import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { searchPosts } from '../services/apiService';
import BlogList from '../components/BlogList';
import LoadingSpinner from '../components/LoadingSpinner';

function SearchResultsPage() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search).get('q'); // Get the search term from the URL

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        const data = await searchPosts(query); // Call the search function
        setResults(data.results); // Assuming the response has a posts field
      } catch (error) {
        console.error('Failed to fetch search results:', error);
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      fetchResults(); // Fetch results if there is a query
    }
  }, [query]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className='min-h-screen'>
      <h1 className='text-3xl font-bold text-gray-900 mb-4 sm:mb-6 mx-5'>Search Results for "{query}"</h1>
     <div className='mx-5'>
     <BlogList posts={results ?? []} />
     </div>
    </div>
  );
}

export default SearchResultsPage;
