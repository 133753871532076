import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import BlogList from '../components/BlogList';
import { fetchReviewCategories } from '../services/apiService';
import LoadingSpinner from '../components/LoadingSpinner';
function ReviewsPage() {
  const [posts, setPosts] = useState([]);
  const [loading, setloading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;
  const [activeCategory, setActiveCategory] = useState('All');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadReviewCategories = async () => {
      try {
        setloading(true);

        const data = await fetchReviewCategories();
        setCategories(data.categories);
        setActiveCategory(data.categories[0]?.name || '');
        setPosts(data.posts);
        setloading(false);

      } catch (error) {
        setloading(false);

        console.error('Failed to fetch review categories:', error);
      }
    };

    loadReviewCategories();
  }, []);

  useEffect(() => {
    const loadPosts = async () => {
      console.log(posts);
      const fetchedPosts = (posts ??[]).filter(post => post.category_id['name'] === activeCategory);
      setFilteredPosts(fetchedPosts);
    };

    loadPosts();
  }, [posts,activeCategory]);

  const [filteredPosts,setFilteredPosts] = useState([]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  if(loading) return <LoadingSpinner />;

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="bg-white min-h-screen"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 sm:mb-6">Reviews</h1>
        <p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-10">Discover the latest app and tech reviews to stay informed about the digital world.</p>
        <div className="flex flex-wrap gap-2 sm:gap-4 mb-8 sm:mb-12">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => {
                setActiveCategory(category.name);
                setCurrentPage(1);
              }}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-150 ease-in-out ${
                activeCategory === category.name
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>
        <BlogList posts={currentPosts} />
     
      </div>
    </motion.div>
  );
}

export default ReviewsPage;
