import React from 'react';
import { motion } from 'framer-motion';

function AboutPage() {
  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="bg-white min-h-screen"
    >
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">About Us</h1>
        
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Mission</h2>
          <p className="text-gray-600 mb-4">
            At Coin Master Blogs, we're dedicated to providing valuable content for gamers, tech enthusiasts, and app users. Our mission is to be your go-to source for the latest information, reviews, and rewards in the digital world.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">What We Offer</h2>
          <ul className="list-disc list-inside text-gray-600 space-y-2">
            <li>In-depth app reviews to help you make informed decisions</li>
            <li>Engaging tech blogs covering the latest trends and innovations</li>
            <li>Exclusive Coin Master reward links and game strategies</li>
            <li>Updates and tips for other popular mobile games</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Commitment</h2>
          <p className="text-gray-600 mb-4">
            We strive to deliver accurate, unbiased, and up-to-date information. Our team of passionate writers and gamers work tirelessly to bring you the best content possible.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Join Our Community</h2>
          <p className="text-gray-600 mb-4">
            Whether you're a casual gamer, a tech geek, or just looking for the latest Coin Master rewards, we welcome you to join our growing community. Stay tuned for regular updates and feel free to reach out with any questions or suggestions.
          </p>
        </section>
      </div>
    </motion.div>
  );
}

export default AboutPage;
