// Function to fetch game categories
export const fetchGameCategories = async () => {
  try {
    const response = await fetch('https://api.coinmaster.blog/categories/game'); // Use fetch instead of axios
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json(); // Assuming the response contains the categories directly
  } catch (error) {
    console.error('Error fetching game categories:', error);
    throw error; // Rethrow the error for handling in the component
  }
};

// Function to fetch game category posts
export const fetchGameCategoryPosts = async () => {
  try {
    const response = await fetch('https://api.coinmaster.blog/game-category-posts'); // Use fetch instead of axios
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
  } catch (error) {
    console.error('Error fetching game category posts:', error);
    throw error; // Rethrow the error for handling in the component
  }
};

// Function to fetch homepage data
export const fetchHomePageData = async () => {
  try {
    const response = await fetch('https://api.coinmaster.blog/homepage', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json(); // Assuming the response contains the arrays directly
  } catch (error) {
    console.error('Error fetching homepage data:', error);
    throw error; // Rethrow the error for handling in the component
  }
};

// Function to fetch post details
export const getPostDetail = async (slug) => {
  try {
    const response = await fetch(`https://api.coinmaster.blog/post/detail?slug=${slug}`); // Use fetch instead of axios
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
  } catch (error) {
    console.error("Error fetching post details:", error);
    throw error; // Rethrow the error for handling in the component
  }
};

// Function to fetch review categories
export const fetchReviewCategories = async () => {
  try {
    const response = await fetch('https://api.coinmaster.blog/categories/review'); // Use fetch instead of axios
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json(); // Assuming the response contains the categories directly
  } catch (error) {
    console.error('Error fetching review categories:', error);
    throw error; // Rethrow the error for handling in the component
  }
};

// Function to search posts by term
export const searchPosts = async (term) => {
  try {
    const response = await fetch(`https://api.coinmaster.blog/search?term=${encodeURIComponent(term)}`); // Use fetch instead of axios
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json(); // Assuming the response contains the search results directly
  } catch (error) {
    console.error('Error searching posts:', error);
    throw error; // Rethrow the error for handling in the component
  }
};

// Function to fetch the sitemap
export const fetchSitemap = async () => {
  try {
    const response = await fetch('https://api.coinmaster.blog/sitemap'); // Use fetch instead of axios
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json(); // Assuming the response contains the sitemap data
  } catch (error) {
    console.error('Error fetching sitemap:', error);
    throw error; // Rethrow the error for handling in the component
  }
};

// Add more API functions as needed
// ... 