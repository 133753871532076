import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import BlogList from '../components/BlogList';
import { fetchGameCategories, getPosts } from '../services/apiService';
import LoadingSpinner from '../components/LoadingSpinner';

function GamesPage() {
  const [posts, setPosts] = useState([]);
  const [loading, setloading] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [activeGame, setActiveGame] = useState('');
  const [gameCategories, setGameCategories] = useState([]);

  useEffect(() => {
    const loadGameCategories = async () => {
      try {
        setloading(true);
        const categories = await fetchGameCategories();
        setGameCategories(categories.categories);
        setPosts(categories.posts);
        setActiveGame(categories.categories[0]?.name || '');
        setloading(false);
      } catch (error) {
        setloading(false);
        console.error('Failed to fetch game categories:', error);
      }
    };

    loadGameCategories();
  }, []);

  useEffect(() => {
    const loadPosts = async () => {
      console.log(posts);
      const fetchedPosts = (posts ??[]).filter(post => post.category_id['name'] === activeGame);
      setFilteredPosts(fetchedPosts);
    };

    loadPosts();
  }, [posts,activeGame]);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };
if(loading){
  return <LoadingSpinner/>
}
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="bg-white min-h-screen"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 sm:mb-6">Game Blogs</h1>
        <p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-10">Explore blogs for your favorite games and discover new strategies!</p>
        <div className="flex md:flex-wrap overflow-x-auto mb-4 sm:mb-8 gap-2 gap-y-4 sticky top-0">
          {gameCategories.map((game) => (
            <button
              key={game.id}
              onClick={() => setActiveGame(game.name)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-150 ease-in-out ${
                activeGame === game.name
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              <p className='text-nowrap'>
                {game.name}
              </p>
            </button>
          ))}
        </div>
        <BlogList posts={filteredPosts} />
      </div>
    </motion.div>
  );
}

export default GamesPage;
