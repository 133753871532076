import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import BlogList from '../components/BlogList';
import ScrollAnimation from '../components/ScrollAnimation';
import { fetchHomePageData } from '../services/apiService';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { Helmet } from 'react-helmet';
function HomePage() {
  const [featurePost, setFeaturePost] = useState([]);
  const [tradingPost, setTrandingPost] = useState([]);
  const [randomPost, setRandomPost] = useState([]);
  const [populat, setPolpularpost] = useState([]);
  const [loading, setloading] = useState(false);
  const [activeCategory, setActiveCategory] = useState('For You');

  useEffect(() => {
    fetchHome();
  }, []);


 


  const fetchHome = async () => {
    setloading(true);
    console.log(`fetching home page data ${Date.now()}`);
    try {
      const data = await fetchHomePageData(); // Fetch homepage data
      console.log(data);
      setFeaturePost(data.featured ?? []);
      setTrandingPost(data.trending ?? [] );
      setRandomPost(data.random ?? []);
      setPolpularpost(data.popular ?? []);
    } catch (error) {
      console.error('Error fetching homepage data:', error);
      setloading(false);
    } finally {
      setloading(false);
    }
  };
  // const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);


  const categories = ['For You', 'Trending', 'Popular'];

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  if(loading){
    return <LoadingSpinner />
  }

  return (
    <motion.div
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}
    transition={pageTransition}
    className="bg-white min-h-screen"
  >
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
    <Helmet>
        <title>{"Coin Master Blogs - Tips, Tricks, and Rewards | coinmaster.blog"}</title>
        <meta data-rh="true" name="description" content="Coin Master Blogs - Your ultimate source for Coin Master tips, tricks, rewards, and game strategies. Daily rewards Links" />
        <meta data-rh="true" name="keywords" content="Dice Dream, Travel Town, Coin Master, Coin Master tips, Coin Master rewards, Coin Master strategy, mobile games, game blog" />
        <meta data-rh="true" property="og:title" content="Coin Master Blogs - Tips, Tricks, and Rewards" />
        <meta data-rh="true" property="og:description" content="Coin Master Blogs - Your ultimate source for Coin Master tips, tricks, rewards, and game strategies. Daily rewards Links" />
        <meta data-rh="true" property="og:image" content={"https://api.coinmaster.blog/api/post" + tradingPost[0]?.cover_image} />
        <meta data-rh="true" property="og:url" content={`https://coinmaster.com`} />
        <meta data-rh="true" name="twitter:card" content="summary_large_image" />
        <meta data-rh="true" name="twitter:title" content="Coin Master Blogs - Tips, Tricks, and Rewards" />
        <meta data-rh="true" name="twitter:description" content="Coin Master Blogs - Your ultimate source for Coin Master tips, tricks, rewards, and game strategies. Daily rewards Links" />
        <meta data-rh="true" name="twitter:image"content={"https://api.coinmaster.blog/api/post" + tradingPost[0]?.cover_image} />
      </Helmet>  
    <ScrollAnimation>
      {featurePost.map((post) => (
        <div className="hero-section relative rounded-3xl overflow-hidden mb-12 sm:mb-16 shadow-2xl">
        <img 
          src={"https://api.coinmaster.blog/api/post"+post.cover_image}
          alt="Hero" 
          className="w-full h-[400px] sm:h-[500px] object-cover bg-gradient-to-r from-black via-black/50 to-transparent"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent">
          <div className="absolute bottom-0 left-0 right-0 p-6 sm:p-10">
            <span className="inline-block px-3 py-1 bg-blue-600 text-white text-xs sm:text-sm font-semibold rounded-full mb-3 sm:mb-4">
              Featured Post
            </span>
            <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold mb-3 sm:mb-4 line-clamp-2">
             {post.title}
            </h1>
            <p className="text-gray-200 text-sm sm:text-base mb-4 sm:mb-6 max-w-2xl line-clamp-3">
             {post.subtitle}
            </p>
            <div className="flex items-center text-white text-xs sm:text-sm">
              <img 
                src="https://randomuser.me/api/portraits/men/32.jpg" 
                alt="Admin" 
                className="w-10 h-10 sm:w-12 sm:h-12 rounded-full mr-3 sm:mr-4 border-2 border-white"
              />
              <div>
                <span className="font-medium text-sm sm:text-base block">Admin</span>
                <span className="font-medium text-sm sm:text-base flex">
                  <span className=" text-gray-300"> {post.created_at}</span>
                 <span className="hidden md:block text-gray-300"> • {post.read_time} mins read</span>
                </span>
              </div>
            </div>
            <Link 
              to={`/post/${post.slug}`} 
              className="absolute bottom-6 right-6 sm:bottom-10 sm:right-10 bg-white text-blue-600 px-4 py-2 rounded-full text-sm font-medium hover:bg-blue-50 transition duration-300"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
      ))}
    </ScrollAnimation>

    <ScrollAnimation>
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 sm:mb-6">Explore Daily Rewards</h1>
      <h2 className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-10">Here, we share game tips, reviews, and stories that inspire your next adventure.</h2>
    </ScrollAnimation>

    <ScrollAnimation>
      <div className="flex flex-wrap gap-2 sm:gap-4 mb-8 sm:mb-12">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-150 ease-in-out ${
              activeCategory === category
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {category}
          </button>
        ))}
      </div>
    </ScrollAnimation>

    {/* <BlogList posts={tradingPost} /> */}

  {  activeCategory === 'Trending' ? 
        <BlogList posts={tradingPost} />
      :
     activeCategory === 'Popular' ? 
        <BlogList posts={populat} />
       :
        <BlogList posts={randomPost} />
        
    }
      
  </div>
  </motion.div>
  );
}

export default HomePage;
