import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { searchPosts } from '../services/apiService';
import mainLogo from '../assets/mainlogo.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setHasScrolled(offset > 0);
    };

    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isActive = (path) => location.pathname === path;

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    console.log(value);
    
    setSearchTerm(value);
    if (value.length > 2) {
      const results = await searchPosts(value);
      setSuggestions(results.results);
    } else {
      setSuggestions([]);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
      setSearchTerm('');
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  return (
    <header className={`bg-white fixed top-0 left-0 right-0 z-50 transition-shadow duration-300 ${hasScrolled ? 'shadow-md' : ''}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img src={mainLogo} alt="Coin Master Blogs Logo" className="h-8 w-auto mr-2" />
              <span className="text-xl font-bold text-gray-700">Coin Master Blogs</span>
            </Link>
          </div>
          <nav className="hidden lg:flex space-x-8">
            {['Home', 'Games', 'Reviews', 'About Us', 'Contact Us'].map((item) => (
              <Link 
                key={item}
                to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                className={`text-base font-medium transition duration-150 ease-in-out ${
                  isActive(item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`)
                    ? 'text-blue-600 border-b-2 border-blue-600' 
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                {item}
              </Link>
            ))}
          </nav>
          <div className="flex items-center">
            <div className="hidden lg:block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className="relative" ref={searchRef}>
                  <form onSubmit={handleSearchSubmit} className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="bg-gray-100 rounded-full py-2 px-4 pr-10 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-48 lg:w-64"
                    />
                    <button type="submit" className="absolute right-3 top-2">
                      <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                    </button>
                  </form>
                  {(suggestions ?? []).length > 0 && (
                    <ul className="absolute z-10 bg-white w-full mt-1 rounded-md shadow-lg">
                      {suggestions.map((suggestion) => (
                        <li key={suggestion.id} className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => {
                          navigate(`/post/${suggestion.slug}`);
                          setSearchTerm('');
                          setSuggestions([]);
                        }}>
                          {suggestion.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <button onClick={() => setIsOpen(!isOpen)} className="lg:hidden bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <div className="mb-4">
              <form onSubmit={handleSearchSubmit} className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="bg-gray-100 rounded-full w-full py-2 px-4 pr-10 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button type="submit" className="absolute right-3 top-2">
                  <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </button>
              </form>
            </div>
            {['Home', 'Games', 'Reviews', 'About Us', 'Contact Us', 'Privacy Policy'].map((item) => (
              <Link 
                key={item}
                to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  isActive(item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`)
                    ? 'text-blue-600 bg-blue-50' 
                    : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'
                }`}
                onClick={() => setIsOpen(false)}
              >
                {item}
              </Link>
            ))}
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;