import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getPostDetail } from '../services/apiService';
import LoadingSpinner from '../components/LoadingSpinner';
import { MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/preview.css';
import BlogList from '../components/BlogList';
import { Helmet } from 'react-helmet';

function PostPage() {
  const [post, setPost] = useState(null);
  const [recommandations, setRecommandation] = useState([]);
  const { slug } = useParams();

  async function fetchPost() {
    let data = await getPostDetail(slug);
    setPost(data.post);
    setRecommandation(data.recommended_posts);
  }

  useEffect(() => {
    fetchPost();
  }, []);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  if (!post) return <LoadingSpinner />;

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="bg-white min-h-screen pt-8 md:pt-16"
    >
      <Helmet>
        <title>{post.title} - Coin Masters Daily Rewards</title>
        <meta data-rh="true" name="description" content={post.subtitle} />
        <meta data-rh="true" name="keywords" content={post.tags} />
        <meta data-rh="true" property="og:title" content={post.title} />
        <meta data-rh="true" property="og:description" content={post.subtitle} />
        <meta data-rh="true" property="og:image" content={"https://api.coinmaster.blog/api/post" + post.cover_image} />
        <meta data-rh="true" property="og:url" content={`https://coinmaster.com/post/${slug}`} />
        <meta data-rh="true" name="twitter:card" content="summary_large_image" />
        <meta data-rh="true" name="twitter:title" content={post.title} />
        <meta data-rh="true" name="twitter:description" content={post.subtitle} />
        <meta data-rh="true" name="twitter:image" content={"https://api.coinmaster.blog/api/post" + post.cover_image} />
      </Helmet>

      <article className="max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 py-3 md:py-8">
        <header className="mb-8">
          <h1 className="line-clamp-3 text-3xl sm:text-4xl font-bold text-gray-900 mb-4">{post.title}</h1>
          <h3 className="line-clamp-3 text-xl sm:text-md font-bold text-gray-500 mb-4">{post.subtitle}</h3>

          <div className="flex items-center text-sm text-gray-500">
            <img src={"https://randomuser.me/api/portraits/men/32.jpg"} alt={"admin"} className="w-10 h-10 rounded-full mr-4" />
            <span className="font-medium text-gray-700 mr-2">Admin</span>
            <span className="mx-2">•</span>
            <span className='line-clamp-1'>{post.created_at}</span>
            <span className="mx-2">•</span>
            <span className='line-clamp-1'>{post.read_time} minutes read</span>
          </div>
        </header>
        <div className="prose prose-lg max-w-none text-pretty">
          <img src={"https://api.coinmaster.blog/api/post" + post.cover_image} alt={post.title} className="w-full h-auto rounded-lg mb-8" />
          <MdPreview  className='prose-lg max-w-none prose-p:m-0 w-96' style={{fontFamily: "M PLUS Rounded 1c",width:"100%"}} modelValue={post.content} editorId={"id"} language='en-US' previewOnly={true} previewTheme='default' />
        </div>
      </article>
      <div className='px-10 md:px-28 py-10'>
        <p className='text-lg font-bold pb-10'>Also Read</p>
        <BlogList posts={recommandations} />
      </div>
    </motion.div>
  );
}

export default PostPage;
