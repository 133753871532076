import React, { useEffect, useState } from 'react';
import { fetchSitemap } from '../services/apiService';
import LoadingSpinner from '../components/LoadingSpinner';

function SitemapPage() {
  const [sitemap, setSitemap] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSitemap = async () => {
      try {
        const data = await fetchSitemap();
        setSitemap(data); // Assuming the data is in XML format
      } catch (error) {
        console.error('Failed to fetch sitemap:', error);
      } finally {
        setLoading(false);
      }
    };

    loadSitemap();
  }, []);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-4">Sitemap</h1>
      <pre className="whitespace-pre-wrap">{sitemap}</pre> {/* Render the XML */}
    </div>
  );
}

export default SitemapPage; 