import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from './ScrollAnimation';

function BlogList({ posts }) {
  return (
    
    <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 md:gap-10">
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify([...posts.map((post) => ({
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": post.title,
            author: {
              "@type": "Person",
              name: "CoderRn",
              url: "https://coderrb.com",
            },
            "description": post.subtitle,
            "image": ["https://api.coinmaster.blog/api/post"+post.cover_image],
          }))]),
        }}
      />
      {posts.map((post) => (
        <ScrollAnimation key={post.slug}>
          <div  className="h-full bg-white rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col">
            <div className="p-4 sm:p-6">
              <div className="aspect-w-16 aspect-h-9 mb-4 sm:mb-6 rounded-xl overflow-hidden">
                <img src={"https://api.coinmaster.blog/api/post"+post.cover_image} alt={post.title} 
                className=" w-full h-48 object-cover" />
              </div>
              <span className="inline-block px-3 py-1 bg-blue-100 text-blue-800 text-xs font-semibold rounded-full mb-2 sm:mb-4">{ post.category_id['name'] ?? "UNRATED"}</span>
              <h3 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-2 sm:mb-3 line-clamp-2 hover:text-blue-600 transition-colors duration-150 ease-in-out">
                <Link to={`/post/${post.slug}`}>{post.title}</Link>
              </h3>
              <p className="font-normal text-gray-500 mb-2 sm:mb-3 line-clamp-2 hover:text-blue-600 transition-colors duration-150 ease-in-out">
              {post.subtitle}
              </p>
              {post.rating && (
                <div className="flex items-center mb-2">
                  {[...Array(5)].map((_, i) => (
                    <svg key={i} className={`w-5 h-5 ${i < post.rating ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>
              )}
              {/* <p className="text-gray-600 text-sm mb-4 line-clamp-3 flex-grow">{post.tags}</p> */}
              <div className="flex items-center text-xs sm:text-sm text-gray-500 mt-auto">
                <img src={"https://randomuser.me/api/portraits/men/32.jpg"} alt={"Admin"} className="w-8 h-8 sm:w-10 sm:h-10 rounded-full mr-2 sm:mr-3 border border-gray-200" />
                <div>
                  <span className="font-medium text-gray-700 block">Admin</span>
                  <span className="text-gray-400">{post.created_at} • {post.read_time} read</span>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </div>
  );
}

export default BlogList;
