import React from 'react';
import { motion } from 'framer-motion';

function PrivacyPolicyPage() {
  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="bg-white min-h-screen py-12 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Privacy Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Information We Collect</h2>
          <p className="text-gray-600 mb-2">We collect information you provide directly to us, such as when you create an account, subscribe to our newsletter, or contact us for support. This may include:</p>
          <ul className="list-disc list-inside text-gray-600 ml-4 mb-2">
            <li>Name and email address</li>
            <li>Username and password</li>
            <li>Profile information (e.g., profile picture)</li>
            <li>Communication preferences</li>
            <li>Any other information you choose to provide</li>
          </ul>
          <p className="text-gray-600 mb-2">We also automatically collect certain information when you use our website, including:</p>
          <ul className="list-disc list-inside text-gray-600 ml-4">
            <li>Log data (e.g., IP address, browser type, pages visited)</li>
            <li>Device information</li>
            <li>Cookie and tracking technology data</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. How We Use Your Information</h2>
          <p className="text-gray-600 mb-2">We use the information we collect to:</p>
          <ul className="list-disc list-inside text-gray-600 ml-4">
            <li>Provide, maintain, and improve our services</li>
            <li>Process transactions and send related information</li>
            <li>Send you technical notices, updates, security alerts, and support messages</li>
            <li>Respond to your comments, questions, and requests</li>
            <li>Communicate with you about products, services, offers, and events</li>
            <li>Monitor and analyze trends, usage, and activities in connection with our services</li>
            <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities</li>
            <li>Personalize and improve the services and provide content or features that match user profiles or interests</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Information Sharing and Disclosure</h2>
          <p className="text-gray-600 mb-2">We may share your information as follows:</p>
          <ul className="list-disc list-inside text-gray-600 ml-4">
            <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf</li>
            <li>In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process</li>
            <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of us or others</li>
            <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company</li>
            <li>With your consent or at your direction</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Data Security</h2>
          <p className="text-gray-600 mb-2">We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. These measures include:</p>
          <ul className="list-disc list-inside text-gray-600 ml-4">
            <li>Use of encryption when transmitting certain sensitive information</li>
            <li>Implementation of physical security measures to protect our servers</li>
            <li>Restriction of access to personal information to employees and contractors who need that access to perform their jobs</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Your Rights</h2>
          <p className="text-gray-600 mb-2">Depending on your location, you may have certain rights regarding your personal information, including:</p>
          <ul className="list-disc list-inside text-gray-600 ml-4">
            <li>The right to access personal information we hold about you</li>
            <li>The right to request that we update, correct, or delete your personal information</li>
            <li>The right to object to our processing of your personal information</li>
            <li>The right to withdraw your consent at any time for processing based on consent</li>
          </ul>
          <p className="text-gray-600 mt-2">To exercise these rights, please contact us using the information provided in the "Contact Us" section.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Changes to This Policy</h2>
          <p className="text-gray-600 mb-2">We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "Last Updated" date at the top of this policy. You are advised to review this policy periodically for any changes.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Contact Us</h2>
          <p className="text-gray-600 mb-2">If you have any questions about this privacy policy or our privacy practices, please contact us at:</p>
          <p className="text-gray-600">
            Email: privacy@coinmasterblogs.com<br />
            Address: 123 Privacy Street, Web City, Internet 12345
          </p>
        </section>
      </div>
    </motion.div>
  );
}

export default PrivacyPolicyPage;
